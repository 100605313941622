import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import { useQuery } from "react-query";
import { API } from "../Api";
import { Case, Default, Switch } from "react-if";
import Error from "../components/Error"
import { Rating } from "react-simple-star-rating";

SwiperCore.use([Navigation]);

const ClientsSay = () => {
  const swiperOption = {
    slidesPerView: 2,
    speed: 700,
    spaceBetween: 30,
    slidesPerGroup: 2,
    loop: true,
    breakpoints: {
      250: {
        slidesPerView: 1,
      },
      320: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      991: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 25,
      },
      1142: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: ".swiper-nav-control .swiper-button-next",
      prevEl: ".swiper-nav-control .swiper-button-prev",
    },
  };

  const { data , isError , isLoading } = useQuery('ratings-list', () => API.get(`/user/ratings`))

  const getInitials = (str) => {
    const results = [];
    const wordArray = str?.split(" ") || [];
    wordArray.forEach((e) => {
    results.push(e[0]);
    });
    return results.join("")?.slice(0, 2);
}

const isArabicText = (text) => {
  const arabicPattern = /[\u0600-\u06FF]/;
  return arabicPattern.test(text.replace(/\s/g, '')); 
};

  return (
    <section className="testimonial-section ptb-120">
      <div className="container">
        <div className="row justify-content-center align-content-center">
          <div className="col-md-10 col-lg-6">
            <div className="section-heading text-center" data-aos="fade-up">
              <h1 className="fw-bold text-white">What’s Clients Say</h1>
              <p className="fw-bold text-white my-5">
                Here’s what our clients are saying about their experience with us.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
        <Switch>
          <Case condition={isError}><Error queryString={'projects-list'}/></Case>
          <Case condition={isLoading}>
            <div className="w-100 text-center">
              <div className="spinner-grow my-3 text-secondary" style={{width:'4rem',height:'4rem'}} role="status"></div>
            </div>
          </Case>
          <Default>
            <div className="col-12">
              <div
                className="position-relative w-100"
              >
                <Swiper {...swiperOption}>
                  {
                    data?.data?.data?.map((rate)=>{
                      return(
                        <SwiperSlide>
                          <div className="py-4 px-3 bg-white rounded-custom position-relative shadow-sm custom_card_rate_m17">
                            <small className="w-100" dir={isArabicText(rate?.desc) ? "rtl" : "ltr"}>{rate?.desc}</small>
                            <div className="author d-flex align-items-center w-100">
                              {
                                rate?.logo ?
                                  <div className="author-img me-3">
                                      <img
                                        src={rate?.logo}
                                        alt="author "
                                        className="rounded-circle"
                                        width="60"
                                        height="60"
                                      />
                                  </div>
                                  :
                                  <span className="custom_no_image_m17 author-img me-3">{getInitials(rate?.name)}</span>
                                }
                              <div className="author-info">
                                <h6 className="m-0">{rate?.name}</h6>
                                <ul className="review-rate mb-0 list-unstyled list-inline">
                                  <Rating
                                    readonly
                                    initialValue={+rate?.rate}
                                    size={24}
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
                <div className="swiper-nav-control">
                  <span className="swiper-button-next"></span>
                  <span className="swiper-button-prev"></span>
                </div>
              </div>
            </div>
          </Default>
        </Switch>
        </div>
      </div>
    </section>
  );
};

export default ClientsSay;
