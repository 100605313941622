import React from "react";
import { Link } from "react-router-dom";

const WhyUs = () => {
  
  return (
    <section className="game-services why_us_container_m17">
      <div className="game-service-bg ">
        <div className="container">
          <div className="row  align-items-center">
            <div className="col-lg-5 col-md-12 why_choose_us_m17">
              <div className="section-title mb-5 mb-lg-0">
                <h2 className="text-white mb-4">
                  Why Choose Us ?
                </h2>
                <p className="mb-2 text-off-white me-5">
                  Empower your website with unmatched speed, security,
                  <div>and reliability </div> 
                </p>
                <a href="#portfolio" className="game-btn-mdm mt-4 custom_test_m17">
                  Explore More
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="game-service-right">
                <div className="row cards_row_container_m17">
                  <div className="col-lg-6 cards_row1_m17" style={{marginTop:'60px'}}>
                    <div className="row mb--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs1.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                            Timely Delivery
                          </h4>
                          <p className="m-0 text-off-white">
                            We prioritize punctuality, ensuring your projects are delivered on time.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs3.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                            Precision and Quality
                          </h4>
                          <p className="m-0 text-off-white">
                            We focus on accuracy and quality in every aspect of our work.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 cards_row2_m17" style={{marginTop:'-60px'}}>
                    <div className="row mt--200">
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs2.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                            Expert Team
                          </h4>
                          <p className="m-0 text-off-white">
                            Our team is highly skilled, bringing extensive experience to every project.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="game-service-wrapper mb-4">
                          <div className="game-service-icon mb-2">
                            <img src="assets/img/gs4.png" alt="icon" />
                          </div>
                          <h4 className="h5 text-white fw-medium m-0 py-3">
                            Innovative Solutions
                          </h4>
                          <p className="m-0 text-off-white">
                            We provide cutting-edge software solutions tailored to your unique needs.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUs