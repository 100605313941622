import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from '../components/common/ScrollToTop';
// import NotFoundScreen from '../components/others/NotFoundScreen';
import Home from "../Views"

function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route path='*' element={<NotFoundScreen />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default index;
