import React, { useEffect } from 'react';
import TopHome from './TopHome';
import WhyUs from './WhyUs';
import OurPortfolio from './OurPortfolio';
import ClientsSay from './ClientsSay';
import Partenrs from './Partenrs';
import 'aos/dist/aos.css';
import AOS from "aos";
import Footer from './Footer';

const Index = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className='custom_cursor_m17'>
      <TopHome/>
      <WhyUs/>
      <OurPortfolio/>
      <ClientsSay/>
      {/* <Partenrs/> */}
      <Footer/>
    </div>
  );
}

export default Index;
