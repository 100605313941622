import React, { Fragment } from 'react'
import Routers from './routers'

// Swiper CSS
import "swiper/css";
import 'swiper/css/navigation';

//bootstrap
import "bootstrap/dist/js/bootstrap.bundle";

//Modal Video
import "react-modal-video/scss/modal-video.scss";
import "./customStylesM17.scss"
import { QueryClient, QueryClientProvider } from 'react-query';
import MouseParticles from 'react-mouse-particles'

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: true,
        refetchOnReconnect: false,
        retry: false
      }
    }
  })

  return (
    <Fragment>
      <MouseParticles 
        g={1.5} // زيادة تأثير الجاذبية لجعل الحركة مثل المطر
        num={10} // عدد الجزيئات
        color={["#8EC5FC", "#E0C3FC", "#F8EDE3"]} // ألوان ناعمة وغير فاقعة
        cull="stats,image-wrapper" 
        level={6} 
        radius={3} // تقليل حجم الكرات
        life={3} // المدة التي تبقى فيها الكرات قبل أن تختفي لتترك أثراً أكبر
        v={1.5} // زيادة سرعة انتشار الكرات لتشبه المطر
      />
      <QueryClientProvider client={queryClient}>
        <Routers />
      </QueryClientProvider>
    </Fragment>
  )
}

export default App