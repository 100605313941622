import { TbReload } from "react-icons/tb"
import { useQueryClient } from "react-query"

const ErrorPage = ({ queryString = undefined, className = 'mt-4'}) => {
    const query = useQueryClient()
    return (
        <div className={`page_action_error fs-2 fw-bold text-danger ${className}`}>
                Loading Error
            <TbReload size={26} color="#d91111b2" className="mx-1" onClick={(e) => {
                e.target.classList.add('playAnimation')
                setTimeout(() => {
                    e.target.classList.remove('playAnimation')
                    query.invalidateQueries(queryString)
                }, 800)
            }}/>
        </div>
    )
}

export default ErrorPage
