import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import intro from '../Images/logo/intro.mp4'

const TopHome = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <section className="hero-it-solution top_home_container_m17">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12 position-relative" style={{overflowX:'hidden' , height:'350px', marginTop:'30px'}}>
            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
              <div class="top_home_text_animation_m17">
                <div class="text-top">
                  <div>
                    <span className="fs-1 text-white">Auxilium</span>
                    <span className="fs-1 text-white">Software</span>
                  </div>
                </div>
                <div class="text-bottom">
                  <div className="fs-2 text-white">Turning Imagination into Reality !</div>
                </div>
              </div>
              <div className="action-btn mt-0 align-items-center d-block d-sm-flex d-lg-flex d-md-flex" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="3000">
                <Link
                  to="#"
                  className="text-decoration-none popup-youtube d-inline-flex align-items-center watch-now-btn mt-3 mt-lg-0 mt-md-0 text-white fs-5"
                  onClick={() => setOpen(true)}
                >
                  <i className="fas fa-play text-danger border-2 border-danger"></i>
                  Watch Demo
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="3000">
            <div className="hero-img position-relative mt-5 mt-lg-0">
              <img
                src="assets/img/banner_image.png"
                alt="hero hero-it-solution "
                className="img-fluid"
              />
              <div className="dots">
                <img
                  src="assets/img/banner_dot.png"
                  alt="dot"
                  className="dot-1"
                />
                <img
                  src="assets/img/banner_dot.png"
                  alt="dot"
                  className="dot-2"
                />
              </div>
              <div className="bubble">
                <span className="bubble-1"></span>
                <span className="bubble-2"></span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ModalVideo
        channel="custom"
        isOpen={isOpen}
        videoId=""
        url={intro}
        onClose={() => setOpen(false)}
      />
    </section>
  );
};

export default TopHome;
