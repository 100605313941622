import React from "react";
import { useQuery } from "react-query";
import { API } from "../Api"
import { Case, Default, Switch } from "react-if";
import Error from "../components/Error"

const ServicePromo = () => {

  const { data , isError , isLoading } = useQuery('projects-list', () => API.get(`/user/projects`))

  return (
    <section className="services-icon" id="portfolio">
      <div className="container OurPortfolio_m17">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="section-heading text-center">
              <h1 className="text-white fw-bold"  data-aos="fade-right" data-aos-duration="500" data-aos-delay="1000">Our Portfolio</h1>
              <p className="text-white fw-bold mt-4"  data-aos="fade-up" data-aos-duration="750" data-aos-delay="1500">
                Credibly grow premier ideas rather than bricks-and-clicks
                strategic theme areas distributed for stand-alone web-readiness.
              </p>
            </div>
          </div>
        </div>
        <Switch>
          <Case condition={isError}><Error queryString={'projects-list'}/></Case>
          <Case condition={isLoading}>
            <div className="w-100 text-center">
              <div className="spinner-grow my-3 text-secondary" style={{width:'4rem',height:'4rem'}} role="status"></div>
            </div>
          </Case>
          <Default>
            <div className="row justify-content-center mt-5" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="500">
              {
                data?.data?.data?.map((project)=>{
                  return (
                    <div className="col-lg-6 col-md-6 mb-5" key={project?.id}>
                      <a
                        href={project?.link}
                        target="blank"
                        className="text-decoration-none text-white w-50"
                      >
                          <div className="single-portfolio-item mb-30">
                            <div className="portfolio-item-img">
                              <img
                                src={project?.img}
                                alt="portfolio"
                              />
                              <div className="portfolio-info">
                                <h5 className="text-white">
                                  {project?.title}
                                </h5>
                                <div className="categories">
                                  <span>Website</span>
                                </div>
                              </div>
                            </div>
                          </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </Default>
        </Switch>
      </div>
    </section>
  );
};

export default ServicePromo;
